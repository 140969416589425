import React, { createContext, useContext, useEffect } from 'react';
import { useGetListViewMilkScheduleDemandItems, useGetListViewMilkScheduleSupplyItems, useGetScheduleDemandItem, useGetScheduleSupplyItem, useGetListViewTotals } from './Schedule.queries';
import { useScheduleSettings } from './ScheduleSettingsStore';
import useSSE from '../../../../hooks/useServerSentEvents';
import useBatching from '../../../../hooks/useBatching';
import { updateCacheRowById } from './common/utils';
import { useQueryClient } from '@tanstack/react-query';
import useSupplyItemsSSE from './useSupplyItemsSSE';
import { useScheduleSelection } from './ScheduleSelectionContext';

const ScheduleContext = createContext({});

export function ScheduleProvider({ children, currentTab }) {
    const { settings, filters } = useScheduleSettings();
    const { flashRows } = useScheduleSelection();
    const { batchedNewSupplyItems, batchedUpdateSupplyItems, batchedUpdateManySupplyItems, status: supplySSEStatus } = useSupplyItemsSSE();
    const { data: demandItemUpdate, status: demandSSEStatus } = useSSE('schedule-demand-items');
    const { batchedItems: batchedInvalidateScheduleTotals, addItems: triggerScheduleTotalsInvalidation } = useBatching(60000);
    const { refetch: fetchUpdateSupplyItem } = useGetScheduleSupplyItem({ queryParams: { _ids: batchedUpdateSupplyItems }, enabled: false });
    const { refetch: fetchUpdateManySupplyItem } = useGetScheduleSupplyItem({ queryParams: { _ids: batchedUpdateManySupplyItems }, enabled: false });
    const { refetch: fetchDemandItem } = useGetScheduleDemandItem({ queryParams: { _ids: demandItemUpdate?._ids }, enabled: false });
    const queryClient = useQueryClient();

    const refreshItems = async (documentIds, queryKeyTag, fetch) => {
        await updateCacheRowById({ queryClient, queryKeyTag, fetch, documentIds });
        flashRows(documentIds);
    };

    useEffect(() => {
        if (batchedNewSupplyItems.length) {
            flashRows(batchedNewSupplyItems);
            queryClient.invalidateQueries({ queryKey: ['list-view-supply-items'] });
            triggerScheduleTotalsInvalidation(['list-view-schedule-totals']);
        }
    }, [batchedNewSupplyItems]);

    useEffect(() => {
        if (batchedUpdateManySupplyItems.length) {
            refreshItems(batchedUpdateManySupplyItems, 'list-view-supply-items', fetchUpdateManySupplyItem);
            triggerScheduleTotalsInvalidation(['list-view-schedule-totals']);
        }
    }, [batchedUpdateManySupplyItems]);

    useEffect(() => {
        if (batchedUpdateSupplyItems.length) {
            refreshItems(batchedUpdateSupplyItems, 'list-view-supply-items', fetchUpdateSupplyItem);
            triggerScheduleTotalsInvalidation(['list-view-schedule-totals']);
        }
    }, [batchedUpdateSupplyItems]);

    useEffect(() => {
        if (demandItemUpdate?._ids?.length) {
            if (demandItemUpdate.method === 'save') {
                queryClient.invalidateQueries({ queryKey: ['list-view-demand-items'] });
                flashRows(demandItemUpdate?._ids);
            } else {
                refreshItems(demandItemUpdate?._ids, 'list-view-demand-items', fetchDemandItem);
            }
            triggerScheduleTotalsInvalidation(['list-view-schedule-totals']);
        }
    }, [demandItemUpdate]);

    useEffect(() => {
        if (batchedInvalidateScheduleTotals.length) {
            queryClient.invalidateQueries({ queryKey: ['list-view-schedule-totals'] });
        }
    }, [batchedInvalidateScheduleTotals]);

    const supplyItems = useGetListViewMilkScheduleSupplyItems({ queryParams: { ...filters, selectedDay: settings.selectedDay }, enabled: currentTab === 0 });
    const demandItems = useGetListViewMilkScheduleDemandItems({ queryParams: { ...filters, selectedDay: settings.selectedDay }, enabled: currentTab === 1 });
    const dashboardTotals = useGetListViewTotals({ queryParams: filters, enabled: settings.showDashboard });

    return (
        <ScheduleContext.Provider
            value={{
                currentTab,
                supplyItems,
                demandItems,
                dashboardTotals,
                supplySSEStatus,
                demandSSEStatus,
            }}
        >
            {children}
        </ScheduleContext.Provider>
    );
}

export const useScheduleData = () => {
    const context = useContext(ScheduleContext);
    if (context === undefined) {
        throw new Error('useSchedulingProvider must be used within a ScheduleProvider');
    }
    return context;
};
