import Region from '../../../constants/region';
import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

const staging = new Environment({
    name: stages.STAGING,
    regions: [Region.UNC, Region.BONGARDS, Region.DEMO],
});

const local = new Environment({
    name: stages.LOCAL,
    regions: [Region.UNC, Region.DEMO, Region.BONGARDS],
});

const dev = new Environment({
    name: stages.DEV,
    regions: [Region.UNC],
});

const can_access_payment_line_item_rates_page = new MockSplit({
    name: 'can_access_payment_line_item_rates_page',
    environments: [local, dev, staging],
});

export default can_access_payment_line_item_rates_page;
