import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, CircularProgress, Grid } from '@mui/material';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Dropzone from '../../configured-components/form/configured-inputs/image-upload/Dropzone';
import { getFileURL } from '../../repository';
import ImageLightbox from '../ImageLightbox';

const sx = {
    box: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    spinner: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    title: {
        marginBottom: '10px',
    },
    pdf: {
        borderRadius: 2,
        border: '1px solid #eaeaea',
        maxHeight: '500px',
        padding: 4,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 8,
    },
    pdfButton: {
        display: 'flex',
        flexDirection: 'column',
    },
    image: {
        maxHeight: '500px',
        flex: '1 1 auto',
        marginBottom: 8,
    },
};

const AttachmentUploaderWidget = ({ file, title, onSubmit, disabled = false, useExpress }) => {
    const [preview, setPreview] = useState();
    const [lightboxOpen, setLightboxOpen] = useState(false);

    const handleOpenLightbox = () => setLightboxOpen(true);
    const handleCloseLightbox = () => setLightboxOpen(false);

    useEffect(() => {
        const getPreview = async () => {
            try {
                const url = await getFileURL(file, true, useExpress);
                setPreview(url);
            } catch (err) {
                // TODO: Set error appropriately
            }
        };

        if (file) {
            getPreview();
        }
    }, [file]);

    if (!file) {
        return (
            <Box padding={2}>
                <Typography sx={sx.marginBottom} variant="h6">
                    {title}
                </Typography>
                {!disabled ? (
                    <Dropzone
                        value={file}
                        preview={preview}
                        message={title.toLowerCase()}
                        handleDrop={onSubmit}
                        multiple={false}
                        // wrap
                    />
                ) : (
                    <Typography sx={sx.marginBottom} variant="h6">
                        No Image for {title}
                    </Typography>
                )}
            </Box>
        );
    }

    const isPdf = file?.includes('.pdf');
    if (!preview) {
        return (
            <Box padding={2} sx={sx.box}>
                <Typography sx={sx.marginBottom} variant="h6">
                    {title}
                </Typography>
                <Grid sx={sx.spinner}>
                    <CircularProgress size={50} color="secondary" disableShrink />
                </Grid>
            </Box>
        );
    }
    return (
        <Box padding={2} sx={sx.box}>
            <Typography sx={sx.marginBottom} variant="h6">
                {title}
            </Typography>
            {isPdf ? (
                <Box sx={sx.pdf}>
                    <iframe
                        src={`https://docs.google.com/gview?url=${encodeURIComponent(preview)}&embedded=true`}
                        style={{
                            minWidth: '100%',
                            maxWidth: '100%',
                            minHeight: '400px',
                            maxHeight: '100%',
                        }}
                        title="Slip"
                    />
                    <Button sx={{ label: sx.pdfButton }} onClick={() => window.open(preview, '_blank').focus()}>
                        <PictureAsPdfIcon color="secondary" fontSize="large" />
                        Download
                    </Button>
                </Box>
            ) : (
                <>
                    <Box
                        component="img"
                        sx={sx.image}
                        alt={title}
                        src={preview}
                        onClick={handleOpenLightbox}
                        style={{ cursor: 'pointer' }}
                        // wrap
                    />
                    <ImageLightbox
                        imageUrl={preview}
                        open={lightboxOpen}
                        handleClose={handleCloseLightbox}
                        // wrap
                    />
                </>
            )}
        </Box>
    );
};

export default AttachmentUploaderWidget;
