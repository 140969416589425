import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles({
    symbol: {
        display: 'block',
        margin: 'auto',
    },
});

const ActivityResumed = ({ circleFill = '#00A2F5' }) => {
    const classes = useStyles();

    return (
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.symbol}>
            <g filter="url(#filter0_ddd_53_20544)">
                <circle cx="19" cy="18" r="15.2727" fill={circleFill} stroke="white" strokeWidth="1.45455" />
                <path
                    d="M1 9l2 2c4.97-4.97 13.03-4.97 18 0l2-2C16.93 2.93 7.08 2.93 1 9zm8 8l3 3 3-3c-1.65-1.66-4.34-1.66-6 0zm-4-4l2 2c2.76-2.76 7.24-2.76 10 0l2-2C15.14 9.14 8.87 9.14 5 13z"
                    fill="white"
                    transform="translate(7, 7)"
                    //
                />
                <path d="M0 0h24v24H0z" fill="none" />
            </g>
            <defs>
                <filter id="filter0_ddd_53_20544" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_53_20544" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_53_20544" result="effect2_dropShadow_53_20544" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect3_dropShadow_53_20544" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow_53_20544" result="effect3_dropShadow_53_20544" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_53_20544" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

export default ActivityResumed;
