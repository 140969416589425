import React from 'react';
import { Marker, OverlayView } from '@react-google-maps/api';
import LocationAnomalyDetails from './helper-components/LocationAnomalyDetails';

const MapPointLocationAnomaly = ({ event, color = '#00A2F5' }) => {
    const latitude = event?.location?.coordinates[1] || 0;
    const longitude = event?.location?.coordinates[0] || 0;

    const center = {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
    };

    const circleIcon = {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: color,
        fillOpacity: 1,
        scale: 10,
        strokeWeight: 0,
    };

    const getPixelPositionOffset = (width, height) => ({
        x: -(width / 2),
        y: -height,
    });

    return (
        <>
            <Marker
                key={event._id}
                position={center}
                icon={circleIcon}
                // wrap
            />
            <OverlayView
                key={event._id}
                position={center}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={getPixelPositionOffset}
                // wrap
            >
                <LocationAnomalyDetails event={event} />
            </OverlayView>
        </>
    );
};

export default React.memo(MapPointLocationAnomaly);
