import React, { useState } from 'react';
import { Box, Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RotateRightIcon from '@mui/icons-material/RotateRight';

const ImageLightbox = ({ imageUrl, open, handleClose }) => {
    const [rotation, setRotation] = useState(0);
    const [scale, setScale] = useState(1);

    const handleRotate = () => {
        setRotation((prev) => prev + 90);
    };

    const handleZoomIn = () => {
        setScale((prev) => prev + 0.25);
    };

    const handleZoomOut = () => {
        setScale((prev) => Math.max(prev - 0.25, 0.25));
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={false} maxHeight={false}>
            <DialogContent style={{ textAlign: 'center', padding: '1rem' }}>
                <Box maxHeight="80vh" maxWidth="80vw" overflow="scroll">
                    <img
                        src={imageUrl}
                        alt="Preview"
                        style={{
                            display: 'block',
                            transform: `rotate(${rotation}deg) scale(${scale})`,
                            transition: 'transform 0.3s ease',
                            objectFit: 'contain',
                        }}
                    />
                </Box>
                <div>
                    <IconButton onClick={handleRotate}>
                        <RotateRightIcon />
                    </IconButton>
                    <IconButton onClick={handleZoomIn}>
                        <ZoomInIcon />
                    </IconButton>
                    <IconButton onClick={handleZoomOut}>
                        <ZoomOutIcon />
                    </IconButton>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ImageLightbox;
