import { useQuery } from '@tanstack/react-query';
import useExpress from '../../../../useExpress';

const getPlaybackDetails = (routeSessionId, isProcessedFluids) => {
    const path = `${isProcessedFluids ? '/processed-fluid' : ''}/route-sessions`;
    const endpoint = `${path}/playback-details/${routeSessionId}`;
    return useExpress({ endpoint });
};

const useGetPlaybackDetails = ({ routeSessionId, isProcessedFluids, enabled = true } = {}) => {
    const queryFn = getPlaybackDetails(routeSessionId, isProcessedFluids);
    const queryKey = ['playback-details', routeSessionId];
    return useQuery({ queryKey, queryFn, enabled });
};

export default useGetPlaybackDetails;
